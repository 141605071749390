<template>
    <div>
      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-light text-gray-900">I nostri Contatti & Dove siamo
              <br class="hidden lg:inline-block"><p class="text-xl font-light">per ogni evenienza contattaci pure</p>
            </h1>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="accordion-button
                  relative
                  flex
                  items-center
                  w-full
                  py-4
                  px-5
                  text-base text-gray-800 text-left
                  bg-white
                  border-0
                  rounded-none
                  transition
                  focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    recapiti 
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">
                    TEL. 045 6102111 FAX 045 6101011 e-mail: info@legnoform.eu <br> AMMINISTRAZIONE: amministrazione@legnoform.eu - ACQUISTI: acquisti@legnoform.eu <br> COMMERCIALE: vendite@legnoform.eu - LOGISTICA: vendite@legnoform.eu
                  </div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingTwo">
                  <button class="accordion-button
                  collapsed
                  relative
                  flex
                  items-center
                  w-full
                  py-4
                  px-5
                  text-base text-gray-800 text-left
                  bg-white
                  border-0
                  rounded-none
                  transition
                  focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    ubicazione
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">
                    La nostra sede Amministrativa è situata a San Bonifacio. Via Villabella, 21 37047 San Bonifacio (VR), vento, Italy
                  </div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingThree">
                  <button class="accordion-button
                  collapsed
                  relative
                  flex
                  items-center
                  w-full
                  py-4
                  px-5
                  text-base text-gray-800 text-left
                  bg-white
                  border-0
                  rounded-none
                  transition
                  focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    altre informazioni
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">
                    LegnoForm S.p.a - Viale Della Vittoria  - 37038 Soave  (VR) - Italy - tel. 045.6102111 Cod. Fisc e Part.IVA 01728010230 - R.E.A. 192849 - Reg. Imp. VR 19398
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-6">
              <a href=mailto:info@legnoform.eu class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">invia un email</a>
              <a href=callto:+045.6102111 class="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">chiama ora</a>
            </div>
          </div>
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <iframe id="gmap_canvas" class="w-full h-full"  loading="lazy" allowfullscreen src="https://maps.google.com/maps?q=Localit%C3%A0%20Villabella,%2037047%20San%20Bonifacio%20VR&t=&z=15&ie=UTF8&iwloc=&output=embed"
           frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </div>
      </section>
    </div>
</template>
<script>
export default {
    data() {
      return{
          
      }  
    },
}
</script>