<template>
    <div>
      <section class="text-gray-700 body-font border-t border-gray-200 mb-0">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-3xl font-light title-font text-gray-900">I nostri Servizi:</h1>
            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">Tutto quello che  possiamo offrire con il nostro consueto impegno</h2>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3 ">
              <div class="flex rounded-lg shadow-lg h-full bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Materie Prime</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">L'impiego di materie prime provenienti da regioni europee di particolare importanza strategico-produttiva è la nostra forza!</p>
                  <router-link to="/qualita" class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3 ">
              <div class="flex rounded-lg shadow-lg h-full bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Trattamento  Termico</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Trattamenti sanitari ad alta tecnologia utilizzando protocolli  HT a norma e ispm 15-fao (autorizzazione fitok  n. it-05-082).</p>
                  <router-link to="/qualita" class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3 ">
              <div class="flex rounded-lg h-full shadow-lg bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Deposito e Trasporto</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">In aggiunta un servizio efficente di deposito e trasporto con mezzi centinati per garantire l’umidita’ dei pallets alla consegna.</p>
                  <router-link to="/qualita" class="mt-3 cursor-pointer text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        <div class="flex-col text-center m-12">
          <h1 class="font-light text-2xl">La nostra Produzione:</h1>
          <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">Per la produzione, l’azienda dispone di...</h2>
          <div class="text-left m-2">
            <p class="text-indigo-500">CORALI</p>
            <p class="leading-relaxed text-gray-800 mb-6">Due linee complete “CORALI”</p>
            <hr>
            <p class="text-indigo-500 mt-4">TRASPORTI</p>
            <p class="leading-relaxed text-gray-800 mb-6">Una rete di autotrasportatori in grado di garantire consegne entro  le 24h dalla richiesta</p>
            <hr>
            <p class="text-indigo-500 mt-4">IMPIANTO DI ESSICAZIONE</p>
            <p class="leading-relaxed text-gray-800 mb-6">Un impianto di essiccazione e trattamento HT come da normativa ISPM 15 FAO (Capacità di 2500 pallets per ogni ciclo. Media di 2 cicli di trattamento al giorno)</p>
          </div>
      </div>
        </div>
      </section>
    </div>
</template>
<script>
export default {
    data() {
      return{
          
      }  
    },
}
</script>