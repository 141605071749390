<template>
    <div>
        <section class="text-gray-700 body-font">
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h1 class="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">LEGNOFORM
                    <br class="hidden lg:inline-block">Produzione di pallets ad alta tecnologia
                </h1>
                <p class="mb-8 leading-relaxed">LEGNOFORM, forte di oltre trent’anni di esperienza nel settore della produzione di Pallets in legno,
                    opera dal 1980 in provincia di Verona, su un’area di 30.000 mq totali. Lo stabilimento di produzione occupa una superficie di 3000 mq
                    ed è stato costruito nell'anno 2000.  L'azienda dispone inoltre di una struttura semovibile di circa 300 mq e di un'area coperta di recente costruzione
                    (anno 2012) di 2500 mq.</p>
                <div class="flex justify-center">
                    <a><button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Prodotti</button></a>
                    <router-link to="/servizi" class="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">Servizi</router-link>
                </div>
                </div>
                <!-- inizio immagini -->
                <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <div id="carouselExampleCaptions" class="carousel slide relative" data-bs-ride="carousel">
                    <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                        <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                        ></button>
                        <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                        ></button>
                        <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                        ></button>
                    </div>
                    <div class="carousel-inner relative w-full overflow-hidden">
                        <div class="carousel-item active relative float-left w-full">
                        <img
                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg"
                            class="block w-full"
                            alt="..."
                        />
                        <div class="carousel-caption hidden md:block absolute text-center">
                            <h5 class="text-xl">First slide label</h5>
                            <p>Some representative placeholder content for the first slide.</p>
                        </div>
                        </div>
                        <div class="carousel-item relative float-left w-full">
                        <img
                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg"
                            class="block w-full"
                            alt="..."
                        />
                        <div class="carousel-caption hidden md:block absolute text-center">
                            <h5 class="text-xl">Second slide label</h5>
                            <p>Some representative placeholder content for the second slide.</p>
                        </div>
                        </div>
                        <div class="carousel-item relative float-left w-full">
                        <img
                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg"
                            class="block w-full"
                            alt="..."
                        />
                        <div class="carousel-caption hidden md:block absolute text-center">
                            <h5 class="text-xl">Third slide label</h5>
                            <p>Some representative placeholder content for the third slide.</p>
                        </div>
                        </div>
                    </div>
                    <button
                        class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                    >
                        <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                        class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                    >
                        <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                </div>
            </div>
        </section>
        <!-- sezione 2 -->
        <div class="grid grid-card m-6" >
            <section class="text-gray-700 body-font border-t border-gray-200">
                <div class="flex flex-col items-center mt-8 sm:text-lg">
                    <h1 class="text-center font-light text-4xl" name='prodotti'>I NOSTRI PRODOTTI:</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
                <h1 class="text-center font-light text-2xl px-2 py-3">Pallets:</h1>
                <div class="flex flex-wrap -mx-1 lg:-mx-4">
                    <div class="rounded-lg shadow-lg m-6 bg-white max-w-sm" v-for="(prodotto, index) in prodotti" :key="index">
                        <img class="rounded-t-lg" src="https://mdbootstrap.com/img/new/standard/nature/184.jpg" alt=""/>
                        <div class="p-6">
                        <h5 class="text-gray-900 text-xl font-medium mb-2">{{prodotto.nome}}</h5>
                        <p class="text-gray-700 text-base mb-4">
                           {{prodotto.descrizione}}
                        </p>
                        <button type="button" @click="GoToContattaci()" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">contattaci per info</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <h1 class="font-light text-2xl text-center px-2 py-3">scelti per te:</h1>
        <section class="text-gray-700 body-font">
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div class=" lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600/edf2f7/a5afbd">
                </div>
                <div class="">
                <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{{scelto.nome}}
                    <br class="hidden lg:inline-block">
                </h1>
                <p class="mb-8 text-lg leading-relaxed">{{scelto.descrizione}}</p>
                <div class="flex justify-center">
                    <button type="button" @click="GoToContattaci()" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">contattaci per info</button>
                </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return{
            prodotti:{
                prodotto1:{
                    nome:'Pallets in legno',
                    descrizione: 'pallets nuovi di tipo standard mm.800x1200 epal a norme uic 435-2 (autorizzazione epal n. i-006).'
                },
            prodotto2:{
                    nome:'Pallets formato mm. 800x1200 e 1000x1200',
                    descrizione: 'pallets a rendere e pallets a perdere per un solo utilizzo di media e leggera portata.'
                },
            prodotto3:{
                    nome:'Pallets a specifica',
                    descrizione: 'pallets su misura, studiati e prodotti secondo le esigenze di carico del cliente garantendo il massimo confort.'
                },
            },
            scelto:{
                    nome:'Gabbie e casse',
                    descrizione: 'gabbie e casse industriali su specifica del cliente, per un perfetto assemblaggio adatto a ogni tipo di lavoro.'
            }
        }
    },
    methods:{
        GoToContattaci(){
            this.$router.push('/contatti')
        }
    }
}

</script>