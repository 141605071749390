import Vue from 'vue'
import App from './App.vue'
import "tailwindcss/tailwind.css"
import VueRouter from 'vue-router'
import 'tw-elements';

import Home from '@/components/Home.vue'
import Servizi from '@/components/Servizi.vue'
import Qualità from '@/components/Qualità.vue'
import Contatti from '@/components/Contatti.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: "home",
    component: Home,
  },
  {
    path: '/servizi',
    name: "servizi",
    component: Servizi,
  },
  {
    path: '/qualita',
    name: "qualità",
    component: Qualità,
  },
  {
    path: '/contatti',
    name: "contatti",
    component: Contatti,
  },
  {
    path: '',
    redirect: '/home',
  }
]

const router = new VueRouter({
  mode: "history",
  routes,
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
