<template>
    <div>
        <header>
            <div class="text-white bg-gray-800 py-5 px-10 md:flex md:items-center md:justify-between md:px-40">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-light cursor-pointer" @click="home()">Legnoform</div>
                    <div class="md:hidden align-middle">
                        <i class="material-icons align-middle cursor-pointer" @click.prevent="toogle">menu</i>
                    </div>
                </div>
                <!-- <div :class="open ? 'block' : 'hidden'"> -->
                    <div 
                        v-for="link in navLinks"
                        :key="link.code"
                        @click="goToLink(link)"
                        :class="{
                                    'bg-purple-800 py-2 px-3 rounded-md text-white': link.code == currentRouteName,
                                    'hover:scale-110 transform transition duration-500 cursor-pointer': link.code !== currentRouteName
                                }"
                        class="flex flex-col font-light cursor-pointer text-left md:block  md:mx-3 md:text-right mt-3 md:mt-0 text-white"
                        >
                            {{ link.label }}
                        
                    </div>
                <!-- </div> -->
                </div>
        </header>
    </div>
</template>
<script>
export default {
    data() {
      return{
        open: false,
        navLinks: [
                {
                label: "home",
                code: "home"
                },
                {
                label: "servizi",
                code: "servizi"
                },
                {
                label: "qualità",
                code: "qualità"
                },
                {
                label: "contatti",
                code: "contatti"
                }
            ]
        } 
    },
    computed: {
        currentRouteName() {
            return this.$route.matched[0].name;
        },
    },
    methods: {
        toogle() {
            this.open = !this.open;
        },
        goToLink(link) {
            this.$router.push({
                name: link.code
            });
        },
        home(){
            this.$router.push('/home')
        }
    },
}
</script>